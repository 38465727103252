import axios from "axios"
import { RMALineItem, WarrantyInfo } from "./types"
import { cx } from "@/types"

export default class WarrantyService {
  private endpoint: string

  constructor() {
    this.endpoint = new URL("/api/v1/warranty-proxy", process.env.VUE_APP_MIDDLEWARE_URL).toString()
  }

  /**
   * Wrapper function doing the heavy lifting for the fetchWarranty functionality
   *   This will return a rejected Promise if the product group has no serial numbers
   *     and will also reject if the warranty info lookup fails (invalid serial numbers, etc)
   *   This will resolve with a merged array containing all pertanent warranty info by item if successful
   * @param workingReturn
   * @returns excel rows array with line item and warranty information
   */
  public fetchWarranty(workingReturn: cx.Returns.Return): Promise<WarrantyInfo[]> {
    return new Promise((resolve, reject) => {
      if (!workingReturn.productGroup.hasSerialNumber)
        reject("Return must contain serial number to fetch warranty info.")
      const lineItemArray =
        workingReturn.rowData?.map(lineItem => {
          return {
            serialNumber: lineItem.serialNumber || "",
            productType: workingReturn.productGroup.name || "",
            productSubType: "", // this is always null/blank according to ws.sensus.com spec
            lookupId: lineItem.id?.toString() || "",
            division: workingReturn.productGroup.salesforceProduct!.type || "",
            rmaCaseNumber: workingReturn.referenceNumber,
            quantity: lineItem.quantity,
            itemNumber: lineItem.itemNumber,
          }
        }) || []
      if (!lineItemArray.length) reject("Return must contain line items to fetch warranty info.")
      this.fetchWarrantyInfo(lineItemArray)
        .then(result => resolve(this.parseWarrantyInfo(result.data)))
        .catch(reason => reject(reason))
    })
  }

  public fetchWarrantyBySerialNumbers(
    workingReturn: cx.Returns.Return,
    serialNumbers: string[]
  ): Promise<WarrantyInfo[]> {
    return new Promise((resolve, reject) => {
      if (!serialNumbers.length) reject("Serial numbers must be included to fetch warranty info.")
      const lineItemArray = serialNumbers.map(serialNumber => {
        return {
          serialNumber: serialNumber,
          productType: workingReturn.productGroup.name || "",
          productSubType: "", // this is always null/blank according to ws.sensus.com spec
          lookupId: serialNumber,
          division: workingReturn.productGroup.salesforceProduct!.type || "",
          rmaCaseNumber: workingReturn.referenceNumber,
        }
      })
      this.fetchWarrantyInfo(lineItemArray)
        .then(result => resolve(this.parseWarrantyInfo(result.data)))
        .catch(reason => reject(reason))
    })
  }

  private fetchWarrantyInfo(items: RMALineItem[]) {
    const xmls = `<?xml version="1.0" encoding="utf-8"?>
        <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
            <soap:Body>
                <getWarrantyBySerialNumber xmlns="http://ws.sensus.com/">
                    <RMASerialNumber>
                        ${items.map((item: RMALineItem) => {
                          return `<RMASerialNumber>
                                      <serialNumber>${item.serialNumber}</serialNumber>
                                      <productType>${item.productType}</productType>
                                      <productSubType>${item.productSubType}</productSubType>
                                      <lookupId>${item.lookupId}</lookupId>
                                      <Division>${item.division}</Division>
                                      <RMACaseNum>${item.rmaCaseNumber}</RMACaseNum>
                                  </RMASerialNumber>`
                        })}
                    </RMASerialNumber>
                </getWarrantyBySerialNumber>
            </soap:Body>
        </soap:Envelope>`

    return axios.post(this.endpoint, xmls, {
      headers: {
        "Content-Type": "text/xml; charset=utf-8",
        Host: "ws.sensus.com",
        SOAPAction: "http://ws.sensus.com/getWarrantyBySerialNumber",
      },
    })
  }

  private parseWarrantyInfo(xmlString: string): WarrantyInfo[] {
    return require("xml2js")
      .parseStringPromise(xmlString)
      .then((result: any) => {
        const responseArray =
          result["soap:Envelope"]["soap:Body"][0]["getWarrantyBySerialNumberResponse"][0][
            "getWarrantyBySerialNumberResult"
          ][0]["WarrantyInfo"]

        if (responseArray[0].exception[0]) {
          throw "Warranty lookup failed: " + responseArray[0].exception[0]
        }

        // transform the response array into a simple mapping
        responseArray.map((RMAItem: any) => {
          Object.keys(RMAItem).map(key => {
            // data comes back in arrays but it is really just string data in the first item
            RMAItem[key] = RMAItem[key][0]
          })
        })
        return responseArray
      })
  }
}
