
























































import _ from "lodash"
import { Vue, Component, Prop } from "vue-property-decorator"
import { GridOptions, GridReadyEvent, ClientSideRowModel } from "ag-grid"
import xlsx from "xlsx"
import { AgGridVue } from "ag-grid-vue"
import { Events } from "ag-grid/dist/ag-grid"
import { PropertyKeys } from "ag-grid/dist/lib/propertyKeys"
import { ColDefUtil } from "ag-grid/dist/lib/components/colDefUtil"

@Component({
  name: "grid",
  components: {
    AgGridVue,
  },
  i18n: {
    messages: {
      en: {
        download: "Download",
      },
      es: {
        download: "Descargar",
      },
      ja: {
        download: "ダウンロード",
      },
    },
  },
})
export default class Grid extends Vue {
  downloadMenu: boolean = false
  downloadFormat: "excel" = "excel"

  @Prop({ type: Object, required: true }) readonly gridOptions!: GridOptions
  @Prop({ type: String, required: false, default: "" }) readonly caption!: string
  @Prop({ type: Object, required: false, default: null }) readonly exportOptions!: GridOptions
  @Prop({ type: Function, required: false, default: null }) readonly exportHook!: Function
  @Prop({ type: Boolean, required: false, default: false }) readonly fitColumns!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly loading!: boolean

  get ready() {
    return !_.isNil(this.gridOptions.api)
  }

  getRowCount() {
    if (this.ready) {
      //ClientSideRowModel is an implementation of IRowModel
      let rowModel = this.gridOptions.api!.getModel() as ClientSideRowModel
      return rowModel.getRootNode().allLeafChildren.length
    }
    return 0
  }

  getDisplayedRowCount() {
    if (this.ready) {
      return this.gridOptions.api!.getModel().getRowCount()
    }
    return 0
  }

  download() {
    let workbook = xlsx.read(
      this.gridOptions.api!.getDataAsExcel(_.assign(this.exportOptions, { suppressTextAsCDATA: true }) || {}),
      { type: "binary" }
    )
    if (_.isFunction(this.exportHook)) {
      workbook = this.exportHook(workbook)
    }
    let defaultFileName = this.downloadFormat === "excel" ? "export.xlsx" : "export.csv"
    xlsx.writeFile(workbook, _.get(this.exportOptions, "fileName", defaultFileName))
  }

  mounted() {
    // bind grid setup to `onGridReady` callback
    let Grid = this
    _.assign(Grid.gridOptions, {
      onGridReady(event: GridReadyEvent) {
        // bind all grid events to component
        Object.getOwnPropertyNames(Events)
          .filter(name => {
            return name.startsWith("EVENT")
          })
          .forEach(eventName => {
            Grid.gridOptions.api!.addEventListener(Events[eventName], (...args: any) => {
              Grid.$emit(Events[eventName], args)
            })
          })
        // fit columns to parent width if indicated
        if (Grid.fitColumns) {
          event.api.sizeColumnsToFit()
          event.api.addEventListener("gridSizeChanged", (gridEvent: GridOptions) => {
            gridEvent.api!.sizeColumnsToFit()
          })
          event.api.addEventListener("rowDataChanged", (gridEvent: GridOptions) => {
            gridEvent.api!.sizeColumnsToFit()
          })
        }
        Grid.$emit("ready")
      },
    })
  }

  created() {
    PropertyKeys.ALL_PROPERTIES.push("__ob__")
    ColDefUtil.ALL_PROPERTIES.push("__ob__")
  }
}
