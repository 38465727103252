
















































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { cx } from "@/types"
import _ from "lodash"
import { ContentLoader } from "vue-content-loader"
import s3 from "@/services/s3"

@Component({
  name: "product-header",
  components: {
    ContentLoader,
  },
})
export default class ProductHeader extends Vue {
  private readonly s3service = new s3()
  missingImage: boolean = false
  showPlaceholder: boolean = false
  @Prop({ type: Object, required: true }) readonly product!: cx.Returns.ProductGroup
  @Prop({ type: String, required: true }) readonly name!: string
  @Prop({ type: String, required: false }) readonly description!: string
  @Prop({ type: String, required: false }) readonly caption!: string
  @Prop({ type: Number, required: false, default: 96 }) readonly size!: number
  @Prop({ type: Boolean, required: false, default: false }) readonly loading!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly hideImage!: boolean
  @Prop({ type: String, required: false, default: "Image Missing" }) readonly tooltipText!: string
  @Prop({ type: Boolean, required: false, default: false }) readonly hideTooltip!: boolean

  created() {
    this.showPlaceholder = this.loading
  }

  @Watch("productImage")
  productImageWatcher(value: string | null) {
    this.missingImage = _.isNil(value)
  }

  get productImage(): string | null {
    if (!_.isNil(this.product) && !_.isNil(this.product.id)) {
      return this.s3service.getProductGroupImageUrl(this.product.id, "small")
    } else {
      return null
    }
  }

  productImageError() {
    this.missingImage = true
  }
}
