import _ from "lodash"
import jsPDF from "jspdf"
import "jspdf-autotable"
import moment from "moment"
import { CONFIG } from "./pdf-config"
import { cx } from "@/types"
import WarrantyService from "@/services/warranty"
import { WarrantyInfo } from "../warranty/types"

export default class PDFService {
  private readonly warrantyService = new WarrantyService()

  /**
   * Builds a new jsPDF document using the default config.
   *
   * @param config The config to use for the pdf instance
   * @returns A new jsPDF instance
   */
  private buildDoc(config?: any) {
    const configClone = _.assign({}, CONFIG)
    let doc = new jsPDF(_.assign(configClone, config)) // eslint-disable-line

    doc.customText = function (text, options, x, y) {
      options = options || {}
      const fontSize = this.internal.getFontSize()
      const pageWidth = this.internal.pageSize.getWidth()
      const textWidth = (this.getStringUnitWidth(text) * fontSize) / this.internal.scaleFactor

      if (options.align === "center") {
        this.text(text, (pageWidth - textWidth) / 2, y)
      } else if (options.align === "right") {
        this.text(text, pageWidth - textWidth + x, y)
      } else {
        this.text(text, x, y)
      }
    }
    return doc
  }

  /**
   * Uses a given address object to build an address string.
   *
   * @param address The address object
   * @returns String address
   */
  private buildAddressString(address: any) {
    const addressContent = []
    if (address.attn) {
      addressContent.push(`Attn: ${address.attn}`)
    }
    if (address.description) {
      addressContent.push(address.description)
    }
    Array.prototype.push.apply(addressContent, [
      address.address,
      `${address.city}, ${address.state} ${address.postalCode}`,
      address.country,
    ])
    return addressContent.join("\n")
  }

  private calculateOffset(inputString: String, fontSize: number) {
    const CHARS_PER_LINE = 100 // On average, there are 100 characters before a new line
    const splitString = inputString.split("\n")
    const newLines = splitString.length
    const inputLength = splitString.join("").length

    return (newLines + inputLength / CHARS_PER_LINE) * fontSize
  }

  private truncateComments(comments: String) {
    if (comments.length > 500) {
      return comments.substring(0, 500) + "...\n\n[TRUNCATED]"
    }
    return comments
  }

  private buildRMADetailsString(workingReturn: cx.Returns.Return) {
    const rmaDetails = []
    rmaDetails.push("Customer Account: " + workingReturn.account!.name)
    rmaDetails.push("Requested Action: " + workingReturn.productGroup!.name)
    rmaDetails.push("Total Items: " + workingReturn.rowData!.length)

    const createdDate = moment(workingReturn.created!)
    if (createdDate.isValid()) {
      rmaDetails.push("Date Created: " + createdDate.format("MMM Do YYYY"))
    } else {
      rmaDetails.push("Date Created: " + moment().format("MMM Do YYYY"))
    }
    return rmaDetails.join("\n")
  }

  /**
   * Builds the return document PDF.
   *
   * @param workingReturn The working return object
   * @returns jsPDF instance
   */
  buildReturnDocument(workingReturn: cx.Returns.Return, warrantyInformation?: WarrantyInfo[]): jsPDF {
    const shippingLocation = workingReturn.location.shipping!
    const billingLocation = workingReturn.location.billing!
    const returnLocation = workingReturn.location.return!

    const doc = this.buildDoc()
    doc.setFontSize(12)

    // build shipping label
    doc.text(this.buildAddressString(shippingLocation), 20, 20)
    if (returnLocation.attn) {
      doc.customText(`Attn: ${returnLocation.attn}`, { align: "center" }, 0, 110)
    }
    if (returnLocation.description) {
      doc.customText(`${returnLocation.description}`, { align: "center" }, 0, 120)
    } else {
      doc.customText("SENSUS USA INC", { align: "center" }, 0, 120)
    }
    doc.customText(`${returnLocation.address}`, { align: "center" }, 0, 130)
    doc.customText(
      `${returnLocation.city}, ${returnLocation.state} ${returnLocation.postalCode}`,
      { align: "center" },
      0,
      140
    )
    doc.customText(`${returnLocation.country}`, { align: "center" }, 0, 150)

    doc.setFontStyle("bold")
    doc.text(`RMA Case #${workingReturn.referenceNumber}`, 20, 180)
    doc.setFontStyle("normal")

    // add item listing page
    doc.addPage()
    doc.setFontStyle("bold")
    doc.customText("*** Please include this report with your return ***", { align: "center" }, 0, 30)
    doc.customText(`RMA Case #${workingReturn.referenceNumber}`, { align: "right" }, -20, 45)

    doc.setFillColor(189, 189, 189)
    doc.rect(20, 60, 575, 20, "F")
    doc.customText("RMA Information", { align: "center" }, 0, 75)

    // build table for case information
    doc.autoTable(
      [
        { title: "Shipping Address", dataKey: "shipToAddress" },
        { title: "Billing Address", dataKey: "billToAddress" },
        { title: "RMA Details", dataKey: "rmaDetails" },
      ],
      [
        {
          shipToAddress: this.buildAddressString(shippingLocation),
          billToAddress: this.buildAddressString(billingLocation),
          rmaDetails: this.buildRMADetailsString(workingReturn),
        },
      ],
      {
        theme: "plain",
        margin: { top: 80, bottom: 30 },
        styles: {
          overflow: "linebreak",
        },
      }
    )

    const comments = this.truncateComments(workingReturn.description || "")

    doc.autoTable(
      [{ title: "Case Comments", dataKey: "comments" }],
      [
        {
          comments: comments,
        },
      ],
      {
        theme: "plain",
        margin: { top: 30, bottom: 30 },
        bodyStyles: {
          overflow: "linebreak",
        },
      }
    )

    const offset = this.calculateOffset(comments, 12)

    doc.setFillColor(189, 189, 189)
    doc.rect(20, 240 + offset, 575, 20, "F")
    doc.customText("Items Approved For Return", { align: "center" }, 0, 255 + offset)

    let columns, rows: any

    if (warrantyInformation?.length) {
      // warranty information was returned
      columns = [
        { title: "Serial Number", dataKey: "serialNumber" },
        { title: "Item Number", dataKey: "itemNumber" },
        { title: "Product Description", dataKey: "description" },
        { title: "Manufacturing Date", dataKey: "manufacturingDate" },
        { title: "Issue", dataKey: "issueName" },
        { title: "Quantity", dataKey: "quantity" },
      ]
      // merge warranty info into return line item info for data table
      rows = workingReturn.rowData?.map(row => {
        const warrantyItem = warrantyInformation.find(item => item.mfgSerialNumber === row.serialNumber)
        return {
          serialNumber: row.serialNumber,
          itemNumber: warrantyItem?.itemNumber,
          manufacturingDate: warrantyItem ? moment(warrantyItem.manufacturingDate).format("MM/DD/YYYY") : "",
          description: warrantyItem?.itemDescription,
          quantity: row.quantity,
          issueName: row.issue.name,
        }
      })
    } else {
      // no warranty information given, use basic table
      columns = [
        { title: "Serial Number", dataKey: "serialNumber" },
        { title: "Issue Type", dataKey: "issueType" },
        { title: "Issue", dataKey: "issueName" },
        { title: "Quantity", dataKey: "quantity" },
      ]
      rows = _.map(workingReturn.rowData, (row: cx.Returns.Item) => {
        return {
          serialNumber: row.serialNumber || "",
          quantity: row.quantity.toString() || "",
          issueType: row.issue.type || "",
          issueName: row.issue.name || "",
        }
      })
    }

    // build table of items being returned
    doc.autoTable(columns, rows, {
      theme: "plain",
      startY: 260 + offset,
      bodyStyles: {
        overflow: "linebreak",
      },
      columnStyles: {
        itemNumber: { cellWidth: "wrap" },
        quantity: { cellWidth: 50 },
      },
    })

    return doc
  }
}
