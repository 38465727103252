




import _ from "lodash"
import { Vue, Component } from "vue-property-decorator"
import Trunquee from "@/components/Trunquee.vue"

@Component({
  name: "trunquee-cell",
  components: {
    Trunquee,
  },
})
class TrunqueeCell extends Vue {
  params!: any
  get cellValue() {
    return !_.isNil(this.params.value) ? this.params.value.toString() : ""
  }
}

export default Vue.component("trunquee-cell", TrunqueeCell)
